/* html, body
{
  overflow: hidden;
} */


@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url('./Game/Assets//Fonts/OpenSans-ExtraBold.ttf')  format('truetype');
}

.OpenSans-ExtraBold
{
  font-family: 'OpenSans-ExtraBold';
}

.text-color
{
  color: #fefeff;
}

@media (prefers-reduced-motion: no-preference) {
  .rotation{
    animation: rotation-spin infinite 500s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes rotation-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-item
{
  color: #fefeff;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  font-size: 10vw;
  margin-top: 3vw;
}

.menu-item:first-child
{
  margin-top: 0;
}

.menu-item img
{
  display: block;
}
.menu-item-line
{
  width: 50%;
  margin: auto;
}